import { createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, vShow as _vShow, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-6 add-block float-start" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = {
  key: 0,
  class: "col-12"
}
const _hoisted_7 = {
  key: 1,
  class: "col-12"
}
const _hoisted_8 = {
  key: 2,
  class: "col-12"
}
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = { class: "input-container" }
const _hoisted_11 = { class: "row mt-3" }
const _hoisted_12 = { class: "col csv-select-btn" }
const _hoisted_13 = { class: "col csv-select-btn" }
const _hoisted_14 = { class: "row mt-4 csv-block" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "text-readecsv-blockr" }
const _hoisted_18 = {
  key: 0,
  class: "form-text file-text"
}
const _hoisted_19 = { class: "row mt-6" }
const _hoisted_20 = {
  class: "col",
  style: {"width":"310px !important"}
}
const _hoisted_21 = ["disabled"]
const _hoisted_22 = { class: "col" }
const _hoisted_23 = ["data-kt-indicator", "disabled"]
const _hoisted_24 = { class: "row mt-4 csv-block" }
const _hoisted_25 = { class: "col" }
const _hoisted_26 = { class: "row select-block" }
const _hoisted_27 = { class: "row mt-3" }
const _hoisted_28 = { class: "col" }
const _hoisted_29 = ["data-kt-indicator", "disabled"]
const _hoisted_30 = { class: "row" }
const _hoisted_31 = {
  key: 0,
  class: "col-md-10"
}
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = {
  class: "modal fade",
  tabindex: "-1",
  id: "kt_modal_1"
}
const _hoisted_35 = { class: "modal-dialog modal-dialog-centered modal-xl" }
const _hoisted_36 = { class: "modal-content" }
const _hoisted_37 = { class: "row d-flex justify-content-end" }
const _hoisted_38 = { class: "col-6 m-5" }
const _hoisted_39 = { class: "modal-body" }
const _hoisted_40 = { class: "card-body pt-0" }
const _hoisted_41 = { class: "card-body tableFixHead table-responsive" }
const _hoisted_42 = { class: "table table-striped table-active table-hover table-bordered text-center p-5" }
const _hoisted_43 = { class: "fs-4 fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_model_select = _resolveComponent("model-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[14] || (_cache[14] = _createElementVNode("h4", { class: "p-3 text-primary variant-heading" }, "Select a Variant:", -1 /* HOISTED */)),
              _withDirectives(_createElementVNode("select", {
                class: "form-select border border-primary form-select-solid m-l-12 w-280px",
                "data-control": "select2",
                "data-allow-clear": "true",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.variant) = $event))
              }, _cache[13] || (_cache[13] = [
                _createStaticVNode("<option value=\"\" disabled selected hidden data-v-e60de28c> Please Select a Variant</option><option value=\"sigfox\" data-v-e60de28c>Sigfox</option><option value=\"lora\" data-v-e60de28c>LoRaWAN</option><option value=\"ltem\" data-v-e60de28c>LTE-M</option><option value=\"nbiot\" data-v-e60de28c>NBIOT</option>", 5)
              ]), 512 /* NEED_PATCH */), [
                [_vModelSelect, _ctx.variant]
              ])
            ]),
            (_ctx.variant === 'lora')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _cache[16] || (_cache[16] = _createElementVNode("h4", { class: "p-3 text-primary variant-heading" }, "Select a LNS:", -1 /* HOISTED */)),
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select border border-primary form-select-solid m-l-12 w-280px",
                    "data-control": "select2",
                    "data-allow-clear": "true",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lns) = $event))
                  }, _cache[15] || (_cache[15] = [
                    _createStaticVNode("<option value=\"\" disabled selected hidden data-v-e60de28c> Please Select a LNS</option><option value=\"lora_ttnV3\" data-v-e60de28c>Lora TTN V3</option><option value=\"lora_actility\" data-v-e60de28c>Lora Actility</option><option value=\"lora_alasdeir\" data-v-e60de28c>Lora Alasdeir</option><option value=\"lora_proximus\" data-v-e60de28c>Lora Proximus</option><option value=\"lora_ttn\" data-v-e60de28c>Lora TTN</option><option value=\"chirpstack\" data-v-e60de28c>Chirpstack</option><option value=\"bio_filter\" data-v-e60de28c>Bio Filter</option><option value=\"daizy\" data-v-e60de28c>Daizy</option><option value=\"suez\" data-v-e60de28c>Suez</option><option value=\"north\" data-v-e60de28c>North</option><option value=\"loriot\" data-v-e60de28c>Loriot</option><option value=\"infrax\" data-v-e60de28c>Infrax</option><option value=\"hdc_proximus\" data-v-e60de28c>HDC Proximus</option><option value=\"daizy_nbiot\" data-v-e60de28c>Daizy NB IoT</option><option value=\"machineq_webhook\" data-v-e60de28c>Machineq Webhook</option><option value=\"helium\" data-v-e60de28c>Helium</option><option value=\"senet\" data-v-e60de28c>Senet</option>", 18)
                  ]), 512 /* NEED_PATCH */), [
                    [_vModelSelect, _ctx.lns]
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.variant === 'nbiot' || _ctx.variant === 'ltem')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _cache[18] || (_cache[18] = _createElementVNode("h4", { class: "p-3 text-primary variant-heading" }, "Select a Protocol:", -1 /* HOISTED */)),
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select border border-primary form-select-solid m-l-12 w-280px",
                    "data-control": "select2",
                    "data-allow-clear": "true",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.protocol) = $event))
                  }, _cache[17] || (_cache[17] = [
                    _createElementVNode("option", {
                      value: "",
                      disabled: "",
                      selected: "",
                      hidden: ""
                    }, " Please Select a Protocol", -1 /* HOISTED */),
                    _createElementVNode("option", { value: "udp" }, "UDP", -1 /* HOISTED */),
                    _createElementVNode("option", { value: "mqtt" }, "MQTT", -1 /* HOISTED */)
                  ]), 512 /* NEED_PATCH */), [
                    [_vModelSelect, _ctx.protocol]
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.variant === 'nbiot' || _ctx.variant === 'ltem')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _cache[20] || (_cache[20] = _createElementVNode("h4", { class: "p-3 text-primary variant-heading" }, "Select a Network Connectivity:", -1 /* HOISTED */)),
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select border border-primary form-select-solid m-l-12 w-280px",
                    "data-control": "select2",
                    "data-allow-clear": "true",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.network_connectivity) = $event))
                  }, _cache[19] || (_cache[19] = [
                    _createStaticVNode("<option value=\"\" disabled selected hidden data-v-e60de28c> Please Select a Protocol</option><option value=\"Vodafone\" data-v-e60de28c>vodafone</option><option value=\"Telia\" data-v-e60de28c>Telia</option><option value=\"Telenor\" data-v-e60de28c>Telenor</option><option value=\"Orange\" data-v-e60de28c>Orange</option><option value=\"Telenet\" data-v-e60de28c>Telenet</option><option value=\"1nce\" data-v-e60de28c>1nce</option><option value=\"T-Mobile\" data-v-e60de28c>T-Mobile</option><option value=\"STC Saudi Arabia\" data-v-e60de28c>STC Saudi Arabia</option><option value=\"Bouygues\" data-v-e60de28c>Bouygues</option><option value=\"Magenta\" data-v-e60de28c>Magenta</option>", 11)
                  ]), 512 /* NEED_PATCH */), [
                    [_vModelSelect, _ctx.network_connectivity]
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_9, [
              _cache[21] || (_cache[21] = _createElementVNode("h4", { class: "p-3 text-primary variant-heading" }, "Shipment Date:", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_10, [
                _withDirectives(_createElementVNode("input", {
                  type: "date",
                  class: "form-control border border-primary shipment-date",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.shipment_date) = $event))
                }, null, 512 /* NEED_PATCH */), [
                  [_vModelText, _ctx.shipment_date]
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[22] || (_cache[22] = _createElementVNode("div", { class: "col-12" }, [
              _createElementVNode("h4", { class: "text-primary variant-heading" }, " Choose a Source ")
            ], -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(["btn btn-lg upload-csv", { 'btn-primary': _ctx.uploadBtn, 'btn-white': _ctx.credentialsBtn }]),
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.showButton1Data && _ctx.showButton1Data(...args)))
              }, " Upload Credentials File ", 2 /* CLASS */)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(["btn btn-lg credential-csv", { 'btn-primary': _ctx.credentialsBtn, 'btn-white': _ctx.uploadBtn }]),
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.showButton2Data && _ctx.showButton2Data(...args)))
              }, " Select Credentials File ", 2 /* CLASS */)
            ])
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_14, [
            _cache[25] || (_cache[25] = _createElementVNode("div", { class: "col-12 csv-design" }, [
              _createElementVNode("h4", { class: "text-primary upload-heading pt-3 px-3" }, "Upload Credentials File:"),
              _createElementVNode("h5", { class: "text-primary file-format px-3" }, "(.csv file format)")
            ], -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("label", _hoisted_17, [
                  _createElementVNode("input", {
                    type: "file",
                    class: "form-control mt-3",
                    accept: ".csv",
                    onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleFileUpload($event)))
                  }, null, 32 /* NEED_HYDRATION */),
                  (_ctx.fileStatus)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_18, "Invalid File Format"))
                    : _createCommentVNode("v-if", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("button", {
                    type: "button",
                    style: _normalizeStyle([{"float":"left"}, { 
                    backgroundColor: _ctx.isButtonDisabled() ? '#D9D9D9' : '#146EFD',
                    color: _ctx.isButtonDisabled() ? '#696A6E' : '#FFFFFF'
                  }]),
                    class: "btn upload-btn",
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#kt_modal_1",
                    id: "kt_toolbar_primary_button",
                    disabled: _ctx.isButtonDisabled()
                  }, " Preview ", 12 /* STYLE, PROPS */, _hoisted_21)
                ]),
                _cache[24] || (_cache[24] = _createElementVNode("div", { class: "col" }, null, -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("button", {
                    style: _normalizeStyle([{"float":"right"}, { 
                    backgroundColor: _ctx.isButtonDisabled() ? '#D9D9D9' : '#146EFD',
                    color: _ctx.isButtonDisabled() ? '#696A6E' : '#FFFFFF'
                  }]),
                    type: "submit",
                    onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
                    class: "btn upload-btn",
                    "data-kt-indicator": _ctx.loading,
                    disabled: _ctx.isButtonDisabled()
                  }, _cache[23] || (_cache[23] = [
                    _createElementVNode("span", { class: "indicator-label" }, " Upload ", -1 /* HOISTED */),
                    _createElementVNode("span", { class: "indicator-progress" }, [
                      _createTextVNode(" Loading.. "),
                      _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                    ], -1 /* HOISTED */)
                  ]), 12 /* STYLE, PROPS */, _hoisted_23)
                ])
              ])
            ])
          ], 512 /* NEED_PATCH */), [
            [_vShow, _ctx.uploadBtn]
          ]),
          _createCommentVNode("3rd row"),
          _withDirectives(_createElementVNode("div", _hoisted_24, [
            _cache[30] || (_cache[30] = _createElementVNode("div", { class: "col-12 csv-design" }, [
              _createElementVNode("h4", { class: "text-primary p-3" }, "Choose from Credentials File:")
            ], -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _cache[26] || (_cache[26] = _createElementVNode("label", { for: "search-select" }, "Select a File:", -1 /* HOISTED */)),
                _createVNode(_component_model_select, {
                  options: _ctx.options,
                  modelValue: _ctx.filename,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.filename) = $event)),
                  placeholder: "select file"
                }, null, 8 /* PROPS */, ["options", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _cache[28] || (_cache[28] = _createElementVNode("div", { class: "col" }, null, -1 /* HOISTED */)),
                _cache[29] || (_cache[29] = _createElementVNode("div", { class: "col" }, null, -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("button", {
                    style: _normalizeStyle([{"float":"right"}, { 
                    backgroundColor: _ctx.credentialButtonDisabled() ? '#D9D9D9' : '#146EFD',
                    color: _ctx.credentialButtonDisabled() ? '#696A6E' : '#FFFFFF'
                  }]),
                    type: "submit",
                    onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.submitCredential && _ctx.submitCredential(...args))),
                    class: "btn btn-danger",
                    "data-kt-indicator": _ctx.loading,
                    disabled: _ctx.credentialButtonDisabled()
                  }, _cache[27] || (_cache[27] = [
                    _createElementVNode("span", { class: "indicator-label" }, " Send ", -1 /* HOISTED */),
                    _createElementVNode("span", { class: "indicator-progress" }, [
                      _createTextVNode(" Loading.. "),
                      _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                    ], -1 /* HOISTED */)
                  ]), 12 /* STYLE, PROPS */, _hoisted_29)
                ])
              ])
            ])
          ], 512 /* NEED_PATCH */), [
            [_vShow, _ctx.credentialsBtn]
          ]),
          _createCommentVNode("4th row"),
          _cache[36] || (_cache[36] = _createElementVNode("div", { class: "row pt-5" }, [
            _createElementVNode("div", { class: "col text-center mt-3" }, [
              _createElementVNode("h3", { style: {"color":"#009ef7"} }, "Download Sample Files")
            ])
          ], -1 /* HOISTED */)),
          _createCommentVNode("5th row"),
          _cache[37] || (_cache[37] = _createStaticVNode("<div class=\"row text-center border border-primary p-3 rounded mt-3\" data-v-e60de28c><h5 style=\"color:#009ef7;\" data-v-e60de28c>V1 Sample</h5><div class=\"col\" data-v-e60de28c><a class=\"underline\" target=\"_blank\" download href=\"/sample_files/Lora.csv\" data-v-e60de28c> Sample CSV Lora </a></div><div class=\"col\" data-v-e60de28c><a class=\"underline\" target=\"_blank\" download href=\"/sample_files/Sigfox.csv\" data-v-e60de28c> Sample CSV Sigfox </a></div><div class=\"col\" data-v-e60de28c><a class=\"underline\" target=\"_blank\" download href=\"/sample_files/NBIoTEm-Com.csv\" data-v-e60de28c> Sample CSV NBIOT </a></div></div><div class=\"row text-center border border-primary p-3 rounded mt-3\" data-v-e60de28c><h5 style=\"color:#009ef7;\" data-v-e60de28c>V2 Sample</h5><div class=\"col\" data-v-e60de28c><a class=\"underline\" target=\"_blank\" download href=\"/sample_files/V2 Lora.csv\" data-v-e60de28c> Sample CSV Lora </a></div><div class=\"col\" data-v-e60de28c><a class=\"underline\" target=\"_blank\" download href=\"/sample_files/V2 Nbiot.csv\" data-v-e60de28c> Sample CSV NBIOT </a></div></div>", 2)),
          _createElementVNode("div", _hoisted_30, [
            _cache[35] || (_cache[35] = _createElementVNode("div", { class: "col-md-2" }, null, -1 /* HOISTED */)),
            (_ctx.respCSV.success)
              ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                  _createElementVNode("b", null, "Added IDs (" + _toDisplayString(_ctx.respCSV.added.length) + "):", 1 /* TEXT */),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.respCSV.added, (device, index) => {
                    return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(device.deviceId) + ", ", 1 /* TEXT */))
                  }), 128 /* KEYED_FRAGMENT */)),
                  _cache[32] || (_cache[32] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                  _createElementVNode("b", null, "Skipped IDs (" + _toDisplayString(_ctx.respCSV.skipped.length) + "):", 1 /* TEXT */),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.respCSV.skipped, (device, index) => {
                    return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(device.deviceId) + ", ", 1 /* TEXT */))
                  }), 128 /* KEYED_FRAGMENT */)),
                  _cache[33] || (_cache[33] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                  _createElementVNode("b", null, "Error messages (" + _toDisplayString(_ctx.respCSV.errors.length) + "):", 1 /* TEXT */),
                  _cache[34] || (_cache[34] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.respCSV.errors, (device, index) => {
                    return (_openBlock(), _createElementBlock("span", { key: index }, [
                      _createTextVNode(" Device Id " + _toDisplayString(device.deviceId) + ": ", 1 /* TEXT */),
                      (_ctx.isObject(device.message))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_32, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(device.message, (item, index) => {
                              return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(item[0]), 1 /* TEXT */))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]))
                        : _createCommentVNode("v-if", true),
                      (!_ctx.isObject(device.message))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(device.message), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                      _cache[31] || (_cache[31] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                      _createCommentVNode(" </span>  ")
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ])
    ]),
    _createCommentVNode(" new thing "),
    _createCommentVNode(" \r\n  <div class=\"card-title d-flex justify-content-start \">\r\n    <label>Please a select variant:</label>\r\n    <select\r\n      name=\"status\"\r\n      data-control=\"select2\"\r\n      data-hide-search=\"true\"\r\n      v-model=\"variant\"\r\n      class=\"form-select form-select-white form-select-sm m-l-12 w-300px\"\r\n    >\r\n      <option value=\"null\" disabled selected hidden>Variant *</option>\r\n      <option value=\"sigfox\">Sigfox</option>\r\n      <option value=\"lora\">Lora</option>\r\n      <option value=\"nbiot\">NB IoT</option>\r\n    </select>\r\n  </div>"),
    _createCommentVNode("begin::Row"),
    _createCommentVNode(" <div class=\"row g-6 mb-6 g-xl-9 mb-xl-9\">np\r\n    begin::Followers"),
    _createCommentVNode(" <label class=\"text-reader\">\r\n      <input\r\n        type=\"file\"\r\n        class=\"btn btn-primary\"\r\n        accept=\".csv\"\r\n        @change=\"handleFileUpload($event)\"\r\n      />\r\n    </label>\r\n    <div class=\"col-md-5\">\r\n      <button\r\n        type=\"button\"\r\n        style=\"float: right; margin-left: 3px\"\r\n        class=\"btn btn-primary\"\r\n        data-bs-toggle=\"modal\"\r\n        data-bs-target=\"#kt_modal_1\"\r\n        id=\"kt_toolbar_primary_button\"\r\n      >\r\n        Preview\r\n      </button>\r\n      <button\r\n        style=\"float: right\"\r\n        type=\"submit\"\r\n        @click=\"submit\"\r\n        class=\"btn btn-primary\"\r\n        :data-kt-indicator=\"loading\"\r\n      >\r\n        <span class=\"indicator-label\"> Upload </span>\r\n        <span class=\"indicator-progress\">\r\n          Please wait...\r\n          <span\r\n            class=\"spinner-border spinner-border-sm align-middle ms-2\"\r\n          ></span>\r\n        </span>\r\n      </button>\r\n\r\n      <a target=\"_blank\" download href=\"/sample_files/Lora.csv\">\r\n        Sample CSV Lora\r\n      </a>\r\n      <br />\r\n      <a target=\"_blank\" download href=\"/sample_files/Sigfox.csv\">\r\n        Sample CSV Sigfox\r\n      </a>\r\n      <br />\r\n      <a target=\"_blank\" download href=\"/sample_files/NBIoTEm-Com.csv\">\r\n        Sample CSV NBIOT\r\n      </a>\r\n    </div>\r\n\r\n    \r\n    end::Followers"),
    _createCommentVNode("</div>"),
    _createCommentVNode("end::Row"),
    _createElementVNode("div", _hoisted_34, [
      _createElementVNode("div", _hoisted_35, [
        _createElementVNode("div", _hoisted_36, [
          _cache[38] || (_cache[38] = _createElementVNode("div", { class: "modal-header bg-primary" }, [
            _createElementVNode("h6", { class: "modal-title fs-1 text-white text-center" }, "CSV Preview"),
            _createCommentVNode("begin::Close"),
            _createElementVNode("div", {
              class: "btn btn-icon btn-sm btn-active-light-primary bg-light ms-2",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              style: {"border-radius":"20px"}
            }, [
              _createElementVNode("span", { class: "svg-icon svg-icon-2x fs-1 text-primary" }, "X")
            ]),
            _createCommentVNode("end::Close")
          ], -1 /* HOISTED */)),
          _createCommentVNode(" input start "),
          _createElementVNode("div", _hoisted_37, [
            _createElementVNode("div", _hoisted_38, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                onInput: _cache[11] || (_cache[11] = ($event: any) => (_ctx.searchData(_ctx.searchValue))),
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.searchValue) = $event)),
                class: "form-control",
                placeholder: "Search Device"
              }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
                [_vModelText, _ctx.searchValue]
              ])
            ])
          ]),
          _createCommentVNode(" input end "),
          _createCommentVNode(" Pagination start  "),
          _createElementVNode("div", _hoisted_39, [
            _createCommentVNode(" KTDataTable start "),
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("div", _hoisted_41, [
                _createCommentVNode(" Table Start "),
                _createElementVNode("table", _hoisted_42, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", _hoisted_43, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, (headerValue, index) => {
                        return (_openBlock(), _createElementBlock("th", { key: index }, _toDisplayString(headerValue.key), 1 /* TEXT */))
                      }), 128 /* KEYED_FRAGMENT */))
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
                      return (_openBlock(), _createElementBlock("tr", { key: index }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, (headerValue, index) => {
                          return (_openBlock(), _createElementBlock("td", { key: index }, _toDisplayString(item[headerValue.key]), 1 /* TEXT */))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]),
                _createCommentVNode(" Table End ")
              ])
            ]),
            _createCommentVNode(" End KTDatatable ")
          ]),
          _createCommentVNode(" End Pagination ")
        ])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}